import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import "../styles/_feeOptimization.scss";
import ogImage from "../images/OG_Image.png";
import pricingHeadwinds from "../images/pricingHeadwinds.png";

const FeeOptimization = () => (
  <Layout>
    <SEO
      title="Competitive Intelligence"
      ogImage={ogImage}
      keywords={[
        "Flowspring",
        "fee optimization",
        "product",
        "feature",
        "analytics",
      ]}
      description="Mispricing your fund products can be a costly mistake. Flowspring can help you understand the trade-off between fees and growth."
    />
    <div className="content">
      <div className="feeOptimizationTitle">
        {"Understand the "}
        <span className="accent">Fee-Growth</span>
        {" Tradeoff"}
      </div>
      <div className="feeOptimizationBody">
        <div>
          <div className="feeOptimizationSection">
            <h2>Are your fund products overpriced?</h2>
            <p>
              Your category peers are not the only factors that can determine
              how over- or under-priced your shareclasses are. Flowspring&apos;s
              factor model for fund pricing takes dozens of factors into
              account, including category, distribution channel, degree of
              passiveness, and many more.
            </p>
          </div>

          <div className="feeOptimizationSection">
            <h2>
              If you are over- or under-priced, how much is that affecting
              your growth?
            </h2>
            <p>
              Our quantitative methods allow us to tell you exactly how much
              of a headwind or tailwind your organic growth is facing due to
              the level you&apos;ve priced your products. Then you can take action
              to appropriately price your products according to a strategy of
              growth or profitability.
            </p>
          </div>
        </div>
        <img
          alt="Graph of growth of headwind verses the net expense ratio."
          className="pricingHeadwindsChart"
          src={pricingHeadwinds}
        />
      </div>
    </div>
  </Layout>
);

export default FeeOptimization;
